import React, {useState} from 'react';
import {ButtonPriority, StatesButton as OldStatesButton} from 'wix-ui-tpa';
import {classes} from './CheckoutButton.st.css';
import classnames from 'classnames';
import {useControllerProps} from '../../../domain/controllers/ControllerContext';
import {StatesButtonStates, ThreeDotsLoader, StatesButton} from 'wix-ui-tpa/cssVars';

export enum CheckoutButtonDataHook {
  button = 'CheckoutButtonDataHook.button',
  threeDotsLoader = 'CheckoutButtonDataHook.threeDotsLoader',
}

export interface CheckoutButtonProps {
  disabled?: boolean;
  priority?: ButtonPriority;
  text: string;
  isNavigationToCheckoutInProcess?: boolean;
}

export function CheckoutButton({disabled, priority, text, isNavigationToCheckoutInProcess}: CheckoutButtonProps) {
  const accessibilityEnabled = useControllerProps().host.accessibilityEnabled;
  const {navigateToCheckout, shouldCustomizeCheckoutButton, shouldNotShowThreeDotsWhenFinishedLoading} =
    useControllerProps().navigationStore;

  const [checkoutButtonState, setCheckoutButtonState] = useState(StatesButtonStates.IDLE);

  // eslint-disable-next-line no-nested-ternary
  return shouldCustomizeCheckoutButton ? (
    shouldNotShowThreeDotsWhenFinishedLoading ? (
      <StatesButton
        priority={priority}
        className={classnames(classes.customizableCheckoutButton)}
        data-hook={CheckoutButtonDataHook.button}
        data-wix-checkout-button={CheckoutButtonDataHook.button}
        disabled={disabled}
        state={isNavigationToCheckoutInProcess ? StatesButtonStates.IN_PROGRESS : StatesButtonStates.IDLE}
        onClick={() => {
          return navigateToCheckout({accessibilityEnabled});
        }}
        inProgressContent={
          <ThreeDotsLoader data-hook={CheckoutButtonDataHook.threeDotsLoader} className={classes.threeDotButton} />
        }
        idleContent={text}
        upgrade
      />
    ) : (
      <StatesButton
        priority={priority}
        className={classnames(classes.customizableCheckoutButton)}
        data-hook={CheckoutButtonDataHook.button}
        data-wix-checkout-button={CheckoutButtonDataHook.button}
        disabled={disabled}
        state={checkoutButtonState}
        onClick={() => {
          setCheckoutButtonState(StatesButtonStates.IN_PROGRESS);
          return navigateToCheckout({accessibilityEnabled});
        }}
        inProgressContent={
          <ThreeDotsLoader data-hook={CheckoutButtonDataHook.threeDotsLoader} className={classes.threeDotButton} />
        }
        idleContent={text}
        upgrade
      />
    )
  ) : // eslint-disable-next-line sonarjs/no-all-duplicated-branches
  shouldNotShowThreeDotsWhenFinishedLoading ? (
    <OldStatesButton
      priority={priority}
      className={classnames(classes.checkoutButton)}
      data-hook={CheckoutButtonDataHook.button}
      data-wix-checkout-button={CheckoutButtonDataHook.button}
      disabled={disabled}
      state={isNavigationToCheckoutInProcess ? StatesButtonStates.IN_PROGRESS : StatesButtonStates.IDLE}
      onClick={() => {
        return navigateToCheckout({accessibilityEnabled});
      }}
      inProgressContent={
        <ThreeDotsLoader data-hook={CheckoutButtonDataHook.threeDotsLoader} className={classes.threeDotButton} />
      }
      idleContent={text}
      upgrade
    />
  ) : (
    <OldStatesButton
      priority={priority}
      className={classnames(classes.checkoutButton)}
      data-hook={CheckoutButtonDataHook.button}
      data-wix-checkout-button={CheckoutButtonDataHook.button}
      disabled={disabled}
      state={checkoutButtonState}
      onClick={() => {
        setCheckoutButtonState(StatesButtonStates.IN_PROGRESS);
        return navigateToCheckout({accessibilityEnabled});
      }}
      inProgressContent={
        <ThreeDotsLoader data-hook={CheckoutButtonDataHook.threeDotsLoader} className={classes.threeDotButton} />
      }
      idleContent={text}
      upgrade
    />
  );
}
